import React from 'react';
import { render } from 'react-dom';
import App from './app.jsx';
import recaptcha from './services/recaptcha'

window.React = React;

render(
    <App />,
    document.getElementById('main'),
);

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyB3h6mLw97e-vncOwq-i5UuPICPLwtl080",
    authDomain: "abhilashnayak.firebaseapp.com",
    databaseURL: "https://abhilashnayak.firebaseio.com",
    projectId: "abhilashnayak",
    storageBucket: "abhilashnayak.appspot.com",
    messagingSenderId: "426353038260",
    appId: "1:426353038260:web:9673384fedb49dec74d8e1",
    measurementId: "G-FKXM8G1GQK"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.performance();

grecaptcha.ready(function() {
    setInterval(() => {
        grecaptcha.execute('6LfFZ-EUAAAAAB9vaRgzg6Q86u5G7iOIwc0l5Wid', {action: 'contactme'}).then(token => {
            recaptcha.token = token
         });
         grecaptcha.execute('6LfFZ-EUAAAAAB9vaRgzg6Q86u5G7iOIwc0l5Wid', {action: 'requestresume'}).then(token => {
            recaptcha.requestResumeToken = token
         });
    }, 120000)
    
});