import loadable from '@loadable/component';
import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './app.less';
import { HOME, PROFILE } from './constants/paths';

const FullViewLazy = loadable(() => import('./components/full-view/full-view'))
const IntroCardLazy = loadable(() => import('./components/intro-card/intro-card'))
const ErrorCardLazy = loadable(() => import('./components/error'))
const App = () => {
  try {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path={HOME} element={ <IntroCardLazy />} />
          <Route path={PROFILE} element={<FullViewLazy />}/>
        </Routes>
      </BrowserRouter>
    )
  } catch (e) {
    return <ErrorCardLazy code={404} />
  }

}
export default App